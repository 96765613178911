/**
 * Created by dylan on 08/10/14.
 */
'use strict';

var hgValidator = require('../index.js');
var hgValidators = hgValidator.validators;
var toString = Object.prototype.toString;
var typeClass = {
    string: '[object String]',
    number: '[object Number]',
    boolean: '[object Boolean]',
    date: '[object Date]',
    regexp: '[object RegExp]',
    function: '[object Function]',
    object: '[object Object]',
    array: '[object Array]',
    arguments: '[object Arguments]'
};
var objectTypes = {
    boolean: false,
    function: true,
    object: true,
    number: false,
    string: false,
    undefined: false
};

hgValidator.extend({
    /**
     * Validator that always returns false and adds the provided error message.
     *
     * @param msg the error message to emit.
     * @returns {{validator: Function, msg: *}}
     */
    error: function (msg) {
        return {
            validator: function (val) {
                return false;
            },
            msg: msg
        };
    },

    /**
     * Validate a value is less than a max.
     *
     * @param max the maximum value the validator will approve (exclusive)
     * @returns {{validator: validator, msg: string}}
     */
    isLt: function (max) {
        return {
            validator: function (val) {
                return val < max;
            },
            msg: '{PATH} should be less than ' + max
        };
    },

    /**
     * Validate a value is less than or equal too a max.
     *
     * @param max the maximum value the validator will approve (inclusive)
     * @returns {{validator: validator, msg: string}}
     */
    isLte: function (max) {
        return {
            validator: function (val) {
                return val <= max;
            },
            msg: '{PATH} should be less than or equal to ' + max
        };
    },

    /**
     * Validate a value is greater than a min.
     *
     * @param min the minimum value the validator will approve (exclusive)
     * @returns {{validator: validator, msg: string}}
     */
    isGt: function (min) {
        return {
            validator: function (val) {
                return val > min;
            },
            msg: '{PATH} should be greater than ' + min
        };
    },

    /**
     * Validate a value is greater than or equal too a min.
     *
     * @param min the minimum value the validator will approve (inclusive)
     * @returns {{validator: validator, msg: string}}
     */
    isGte: function (min) {
        return {
            validator: function (val) {
                return val >= min;
            },
            msg: '{PATH} should be greater than or equal to ' + min
        };
    },

    isLength: function (min, max) {
        return {
            validator: function (val) {
                if (null != max) {
                    return min <= val.length && max >= val.length;
                } else {
                    return min <= val.length;
                }
            },
            msg: (max) ?
            '{PATH} should be within ' + min + '-' + max + ' characters long' :
            '{PATH} should be more than ' + min + ' characters long'
        };
    },

    /**
     * Validate a value is a String type.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isString: function () {
        return {
            validator: function (val) {
                return 'string' === typeof val ||
                    val && 'object' === typeof val && toString.call(val) === typeClass.string || false;
            },
            msg: '{PATH} should be a string'
        };
    },

    /**
     * Validate a value is a Number type.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isNumber: function () {
        return {
            validator: function (val) {
                return 'number' === typeof val || val instanceof Number;
            },
            msg: '{PATH} should be a number'
        };
    },

    /**
     * Validate a value is a Boolean type.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isBoolean: function () {
        return {
            validator: function (val) {
                return true === val || false === val ||
                    val && 'object' === typeof val && toString.call(val) === typeClass.boolean || false;
            },
            msg: '{PATH} should be true or false'
        };
    },

    /**
     * Validate a value is a Boolean type.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isDate: function () {
        return {
            validator: function (val) {
                return toString.call(val) === typeClass.date && !isNaN(val.getTime());
            },
            msg: '{PATH} should be a date'
        };
    },

    /**
     * Validate value is -1, 0 or 1
     *
     * @returns {{validator: validator, msg: string}}
     */
    isTriState: function () {
        return {
            validator: function (val) {
                switch (val) {
                    case '-1':
                    case '1':
                    case '0':
                    case -1:
                    case 1:
                    case 0:

                        return true;
                    default:

                        return false;
                }
            },
            msg: '{PATH} should be -1, 0 or 1'
        };
    },

    /**
     * Validate that value:
     *      1) is a string.
     *      2) is not an email.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isPlainText: function () {
        return {
            validator: function (val) {
                return 'string' === typeof val && !hgValidators.isEmail().validator(val);
                // TODO: htmlpurifier
            },
            msg: '{PATH} should not have any invalid characters'
        };
    },

    /**
     * Validate that value:
     *      1) is a string.
     *      2) does not contain ('<>).
     *      3) is not an email.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isNoHtmlText: function () {
        return {
            validator: function (val) {
                return 'string' === typeof val && !'<>'.split('').some(function (char) {
                        return -1 !== val.indexOf(char);
                    }) && !hgValidators.isEmail().validator(val);
                // TODO: htmlpurifier
            },
            msg: '{PATH} should not have any invalid characters'
        };
    },

    /**
     * Validate that value:
     *      1) is a string.
     *      2) does not contain @param(max) new lines characters.
     *
     * @param max the maximum consecutive newlines to allow.
     * @returns {{validator: validator, msg: string}}
     */
    hasMaxNewLinesInARow: function (max) {
        max = max || 2;

        return {
            validator: function (val) {
                if ('string' !== typeof val) {
                    return false;
                }
                val = val.replace(/\r|\n|<br\/>|<br \/>/g, '<br>'); // Replace all line breaks with <br>

                return !(new RegExp('(<br>){' + (max + 1) + ',}').test(val));
            },
            msg: '{PATH} should only have ' + max + ' line breaks in a row'
        };
    },

    /**
     * Validate that value:
     *      1) is 24 characters long.
     *      2) is hexadecimal.
     *
     * @param zeroIsValid if true, 0 is a valid MongoId
     * @returns {{validator: validator, msg: string}}
     */
    isMongoId: function (zeroIsValid) {
        return {
            validator: function (val) {
                val = val.toString();

                if (zeroIsValid && '0' === val) {
                    return true;
                }

                return 24 === val.length &&
                    hgValidators.isHexadecimal().validator(val);
            },
            msg: '{PATH} should be a MongoId'
        };
    },

    /**
     * Validate that value exists and it's length is not 0.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isNotEmpty: function () {
        return {
            validator: function (val) {
                return null != val && 0 < val.length;
            },
            msg: '{PATH} should be non-empty'
        };
    },

    /**
     * Validate that value is an array.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isArray: function () {
        return {
            validator: function (val) {
                return Array.isArray(val);
            },
            msg: '{PATH} should be an array'
        };
    },

    /**
     * Validate a string or array consists of only values from another string or array.
     *
     * @param validValues the string(space delimited) or array
     * @returns {{validator: validator, msg: string}}
     */
    containsOnly: function (validValues) {
        if ('string' === typeof validValues || validValues instanceof String) {
            validValues = validValues.split(' ');
        }

        validValues = validValues || [];

        return {
            validator: function (values) {
                if ('' === values || (Array.isArray(values) && 0 === values.length)) {
                    return true;
                }

                if ('string' === typeof values || values instanceof String) {
                    values = values.split(' ');
                }

                return values.every(function (val) {
                    return true === hgValidators.isIn(validValues).validator(val);
                });
            },
            msg: (1 < validValues.length) ?
            '{PATH} should only contain ' + validValues.slice(1).join(', ') + ' and ' + validValues[0] :
            '{PATH} should only contain ' + validValues
        };
    },

    /**
     * Validate a string or array consists of only unique values.
     *
     * @param prop a property to pull out of an object, if an collection is passed.
     * @param alias an alternative name for prop in the error message.
     * @returns {{validator: validator, msg: string}}
     */
    containsUnique: function (prop, alias) {
        var message = alias || prop;

        if (message) {
            message = ' ' + message;
        }

        return {
            validator: function (values) {
                var items = [];

                var value;

                for (var i = 0, len = values.length; i < len; i += 1) {
                    value = values[i];

                    if ('string' === typeof prop || prop instanceof String) {
                        value = value[prop];
                    }

                    if (-1 !== items.indexOf(value)) {
                        return false;
                    } else {
                        items.push(value);
                    }
                }

                return true;
            },
            msg: '{PATH}' + message + ' should be unique'
        };
    },

    /**
     * Validate that value:
     *      1) isPlainText.
     *      2) is between 0, 25 characters long.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isCity: function () {
        return {
            validator: function (val) {
                return hgValidators.isPlainText().validator(val) &&
                    hgValidators.isLength(0, 25).validator(val);
            },
            msg: '{PATH} should be an be a city'
        };
    },

    /**
     * Validate that value:
     *      1) isPlainText.
     *      2) is between 0, 30 characters long.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isCountry: function () {
        return {
            validator: function (val) {
                return hgValidators.isPlainText().validator(val) &&
                    hgValidators.isLength(0, 30).validator(val);
            },
            msg: '{PATH} should be an be a country'
        };
    },

    /**
     * Validate that value:
     *      1) isPlainText.
     *      2) is between 0, 25 characters long.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isProvince: function () {
        return {
            validator: function (val) {
                return hgValidators.isPlainText().validator(val) &&
                    hgValidators.isLength(0, 25).validator(val);
            },
            msg: '{PATH} should be an be a province'
        };
    },

    /**
     * Validate that value:
     *      1) is a string.
     *      2) is not an empty string.
     *      3) does not contain any invalid postal code characters.
     *      4) is between 0, 16 characters long.
     *
     * @returns {{validator: validator, msg: string}}
     */
    isPostalCode: function () {
        return {
            validator: function (val) {
                return 'string' === typeof val &&
                    '' !== val &&
                    0 === val.replace(/[a-zA-Z0-9\- \.]/g, '').length &&
                    hgValidators.isLength(0, 18).validator(val);
            },
            msg: '{PATH} should be an be a postal code'
        };
    },

    /**
     * Validate that value:
     *      1) does not contain any non-phonenumber characters.
     *      2) has less than 14 characters (digits only).
     *
     * @returns {{validator: validator, msg: string}}
     */
    isPhoneNumber: function () {
        return {
            validator: function (val) {
                var numDigits = val.replace(/[^0-9]/g, '').length;

                return val.replace(/[^0-9\-\.\(\) \+\/]/, '') === val &&
                    14 > numDigits && 7 <= numDigits;
            },
            msg: '{PATH} should be a phone number'
        };
    }
});